<template>
  <section>
    <div v-if="pageLoaded">
      <NavBody>
        <template #body>
          <div class="flex items-center justify-between relative">
            <div class="w-full" v-if="jobDescription">
              <div class="flex items-center mb-4 cursor-pointer" @click="$router.push('/jobs')">
                <Back />
                <p class="text-white ml-2">Jobs</p>
              </div>
              <p class="font-normal lg:text-4xl text-2xl text-white font-albert lg:mb-3 capitalize">{{ role(jobDescription) }}</p>
              <div class="flex items-center mb-2 overflow-scroll scrollbar-hide snap-x scroll-pl-10 whitespace-nowrap">
                <div class="flex items-center snap-start">
                  <Calendar />
                  <p class="ml-1 font-normal text-sm lg:text-base text-white capitalize">{{ jobDescription.type.split('_').join('-') }}</p>
                </div>
                <div class="flex items-center snap-start ml-2 mr-2">
                  <Pin />
                  <p class="ml-1 font-normal text-sm lg:text-base text-white capitalize">{{ jobDescription.work_type.split('_').join('-') }}</p>
                </div>
                <div class="flex items-center snap-start">
                  <Tag />
                  <p class="ml-1 font-normal text-sm lg:text-base text-white">
                    {{ formatNumber(jobDescription?.salary_min) || jobDescription?.salary_min?.formatted }} -
                    {{ formatNumber(jobDescription?.salary_max) || jobDescription?.salary_max?.formatted }} /
                    {{ jobDescription.pay_period.split('_').join(' ') }}
                  </p>
                </div>
              </div>
              <p class="text-white/60 ext-base font-normal">Posted {{ moment(jobDescription.created_at).fromNow() }}</p>
            </div>
            <div class="bg-white p-3 inset-x-0 w-full max-h-[96px] -bottom-7 md:relative fixed md:hidden h-screen">
              <Button
                :label="isClosed ? 'This Job is Closed' : 'Apply for this Role'"
                :disabled="disabled"
                color="primary"
                @click.stop="proceedToApply(jobDescription)"
                full
                class=""
              />
            </div>
            <Button
              data-test="apply-closed-button"
              :label="isClosed ? 'This Job is Closed' : 'Apply for this Role'"
              :disabled="disabled"
              color="white"
              outline
              @click.stop="proceedToApply(jobDescription)"
              class="hidden md:block"
            />
          </div>
        </template>
      </NavBody>
      <div class="lg:px-20 px-5 py-8 mb-12 md:mb-0">
        <div class="mb-4 w-full flex-col" v-if="!userDetails?.candidate.resume">
          <router-link class="text-brand-primary" to="/profile?resume=1"> Upload Your resume/CV </router-link> to apply for this job.
        </div>
        <div class="mb-4">
          <p class="font-medium text-xl text-brand-black mb-2">About this Job</p>
          <div v-html="jobDescription?.description" class="description"></div>
        </div>
        <div>
          <p class="font-medium text-xl text-brand-black mb-2">Job Specifications</p>
          <ul class="text-brand-black/70 font-light list-inside list-disc">
            <li v-for="item in jobDescription?.skills" :key="item" class="">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
    <Loading :is-loading="!pageLoaded" className="flex justify-center py-6 lg" />

    <apply-note v-if="showNoteModal" @show-question="proceedToQUestion" @close-modal="showNoteModal = false" :jobDescription="jobDescription" />
    <apply-questions
      v-if="showQuestionModal"
      @show-note="editApplication"
      @show-cv="proceedToCv"
      @close-modal="showQuestionModal = false"
      :jobDescription="jobDescription"
      :linkedin_url="linkedin_url"
    />
    <apply-cv
      v-if="showCvModal"
      @show-question="proceedToQUestion"
      @show-preview="proceedToPreview"
      @close-modal="showCvModal = false"
      :jobDescription="jobDescription"
      :talent="userDetails"
    />
    <apply-preview
      v-if="showPreviewModal"
      @submit="apply"
      @show-note="editApplication"
      @close-modal="showPreviewModal = false"
      :jobDescription="jobDescription"
      :talent="userDetails"
      :linkedin_url="linkedin_url"
      :submit_content="submit_content"
    />
    <apply-success
      v-if="showSuccessModal"
      :jobDescription="jobDescription"
      :userDetails="userDetails"
      :disabled="disabled"
      :isLoading="isLoading"
      @close-modal="$router.push('/applied')"
    />
  </section>
</template>

<script setup>
import moment from 'moment';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { errorMessage, alert, formatNumber } from '@/utils/helper';

import NavBody from '@/components/NavBody.vue';
import Back from '@/assets/icons/back.svg?inline';
import Calendar from '@/assets/icons/calendar.svg?inline';
import Tag from '@/assets/icons/tag.svg?inline';
import Pin from '@/assets/icons/pin.svg?inline';
import Button from '@/components/Button.vue';
import Loading from '@/components/Loading.vue';
import ApplyNote from '@/components/job/ApplyNote.vue';
import ApplyQuestions from '@/components/job/ApplyQuestions.vue';
import ApplyCv from '@/components/job/ApplyCv.vue';
import ApplyPreview from '@/components/job/ApplyPreview.vue';
import ApplySuccess from '@/components/job/ApplySuccess.vue';

const showNoteModal = ref(false);
const showQuestionModal = ref(false);
const showCvModal = ref(false);
const showPreviewModal = ref(false);
const showSuccessModal = ref(false);

const store = useStore();
const route = useRoute();
const router = useRouter();
const jobDescription = ref(null);
const pageLoaded = ref(false);
const isLoading = ref(false);
const submit_content = ref('Submit application');

const userDetails = computed(() => store.getters['auth/userDetails']);

const disabled = computed(() => jobDescription.value?.status === 'closed');

const isClosed = computed(() => jobDescription.value?.status === 'closed');

const linkedin_url = computed(() => {
  const user = store.getters['auth/userDetails'];
  const social_links = user.candidate.social_links;
  if (!social_links) return '';
  const linkedin = social_links.filter((link) => link.platform == 'linkedin')[0];
  return linkedin ? linkedin.url : '';
});
const role = (job) => job.role_title || job.title_name;

const proceedToApply = (job) => {
  if (!userDetails.value.candidate.resume) {
    alert('Resume not found. Please upload your resume');
    return router.push('/profile?resume=1');
  }
  if (job.external_website_url) {
    return (window.location.href = job.external_website_url);
  }
  showNoteModal.value = true;
};
const proceedToQUestion = () => {
  showNoteModal.value = false;
  showCvModal.value = false;
  showQuestionModal.value = true;
};
const proceedToCv = () => {
  showNoteModal.value = false;
  showQuestionModal.value = false;
  showCvModal.value = true;
};
const proceedToPreview = () => {
  showNoteModal.value = false;
  showQuestionModal.value = false;
  showCvModal.value = false;
  showPreviewModal.value = true;
};

const editApplication = () => {
  showPreviewModal.value = false;
  showQuestionModal.value = false;
  showNoteModal.value = true;
};

const apply = async () => {
  try {
    isLoading.value = true;
    submit_content.value = 'Submitting...';
    let job = await store.getters['global/getJob'](route.params.id);
    if (!job) {
      //might be a first site visit for the browser
      await store.dispatch('global/getAllJobs');
      job = await store.getters['global/getJob'](route.params.id);
    }
    const jobForm = await store.getters['global/getJobForm'];
    await store.dispatch('global/applyToJob', { id: jobDescription.value?.uuid || job?.uuid, ...jobForm });
    showPreviewModal.value = false;
    showSuccessModal.value = true;
    submit_content.value = 'Submitted';
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.log(error.response.data.errors);
      // return error.response.data.message;
    }
    alert(errorMessage(error), 'error');
    isLoading.value = false;
    submit_content.value = 'Submit application';
  }
};

const getJob = async () => {
  try {
    jobDescription.value = await store.dispatch('global/getSingleJob', route.params.id);
    process.env.NODE_ENV === 'development' ? console.log(jobDescription.value) : '';
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    pageLoaded.value = true;
  }
};

onMounted(async () => {
  getJob();
  store.commit('global/setJobForm', {});
  await store.dispatch('global/getEnums');
});
</script>

<style lang="scss" scoped>
.description::v-deep {
  line-height: 1.8rem;

  ul {
    list-style: disc;
    margin-left: 1rem;
    padding-left: 1rem;
  }
}
</style>
