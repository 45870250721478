<template>
  <!-- Apply Modal -->
  <Modal size="3xl" class=" overflow-y-scroll">
    <template #header>
      <div class="flex flex-row items-center mb-4 w-full">
        <Back class="cursor-pointer text-brand" @click="emits('show-question')" />
        <p class="text-brand ml-2 cursor-pointer" @click="emits('show-question')">Back</p>
      </div>
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center w-4/6">
          <img :src="jobDescription.employer.logo_url" class="h-8 w-8 rounded-full object-cover mr-2" alt="companyLogo" />
          <p class="font-cooper font-normal lg:text-lg text-base text-brand-black/70">Apply as a {{
            role(jobDescription) }}</p>
        </div>
        <Close @click.stop="emits('close-modal')" class="cursor-pointer" />
      </div>
      <div class="w-full bg-gray-200 mx-0 overflow-hidden border border-gray-300 mt-2">
        <div class="bg-brand-primary text-xs leading-none py-1" style="width: 90%"></div>
      </div>
    </template>
    <template #body>
      <div class="text-brand-black text-opacity-90 text-base font-bold mb-2.5 flex flex-row  h-12 md:h-52">
        <img src="@/assets/icons/pdf.svg" class="mr-2 h-8" alt="" />
        <a :href="talent?.candidate?.resume" @click.prevent="resumeUrl"
          class="text-[#8F00E6] text-[15px] font-semibold ml-5 h-8">{{ resume_label }}</a>

      </div>
      <div class="text-brand-black text-opacity-90 text-base font-bold flex flex-row text-center justify-center items-center">
        <input ref="selectedFile" type="file" accept=".pdf" class="hidden focus:ring-0 focus:outline-none focus:border-0"
          @change="previewResume" />
        <div v-if="files.length && error" class="flex flex-col justify-center items-center">
          <UploadFailed />
          <div class="flex flex-col justify-center items-center">
            <p class="font-medium text-[#E94444] text-xl">Failed to Upload</p>
            <p class="font-normal text-base text-brand-black/70">File too big</p>
          </div>
        </div>
        <div v-else-if="files.length && !error" class="flex flex-col justify-center items-center">
          <UploadSuccess />
          <div class="flex flex-col justify-center items-center">
            <p class="font-medium text-brand-black text-xl">Uploading Document</p>
            <p class="font-normal text-base text-brand-black/70">{{ uploadInfo }}</p>
          </div>
        </div>

      </div>
      <div class="text-brand-black text-opacity-90 text-base font-bold flex flex-row text-center justify-center items-center">
        <div class="flex justify-center items-center mt-5 mb-5 md:mt-10 md:mb-10 cursor-pointer" v-if="files.length" @click="clearFile">
            <Trash />
            <p class="font-semibold text-base text-brand-black/40 ml-2">Clear file</p>
          </div>
      </div>
    </template>
    <template #footer>
      <div class="container">
        <div class="sm:grid md:flex justify-between md:flex-row columns-1 md:columns-2">
          <div class="flex flex-row items-center justify-between md:space-x-32 mb-3 columns-1">

            <div class="grid grid-cols-1 justify-between md:space-x-2 mb-3 columns-1 text-brand-black">
              <p class=" text-base font-bold mb-2">Hiring Contact</p>
              <div class="flex flex-row columns-2 w-full">
                <div class="w-1/3">
                  <img :src="hiring_img" alt="profile" class="h-8 w-8 rounded-full object-cover mr-2" />
                </div>
                <div class="w-2/3">
                  <p class="font-bold text-sm m-0">{{ hiring_name }}</p>
                  <p class="text-opacity-60 font-light text-xs order-last" v-if="hiring_position">{{
                    hiring_position }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex  flex-row items-center">
            <tam-button :label="upload_text" color="default" :outline="true" class="self-start mr-5 border-brand-primary relative md:top-6 cv-btn"
              btnSize="w-full md:w-28" @click="uploadCv" :loading="isLoading" :disabled="disabled" />
            <tam-button label="Continue" color="primary" class="self-end" btnSize="w-full md:w-28"
              @click="emits('show-preview')" :disabled="disabled" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { errorMessage, alert, saveResumeUrl } from '@/utils/helper';
import UploadSuccess from '@/assets/icons/uploadSuccess.svg?inline';
import UploadFailed from '@/assets/icons/uploadFailed.svg?inline';
import Trash from '@/assets/icons/trash.svg?inline';
import Close from '@/assets/icons/close.svg?inline';
import Back from '@/assets/icons/back-brand.svg?inline';
import Modal from '@/components/Modal.vue';
import TamButton from "@/components/TamButton.vue";

const store = useStore();

const emits = defineEmits(['close-modal', 'show-question', 'show-preview', 'input']);
const props = defineProps({
  jobDescription: {
    type: Object,
    required: true,
  },
  talent: {
    type: Object,
    required: true,
  }
});
const selectedFile = ref(null);
const file = ref(null);
const files = ref([]);
const error = ref(false);
const note = ref('');
const isLoading = ref(false);
const disabled = ref(false);

const role = (job) => job.role_title || job.title_name;

const resume_label = computed(() => `${ props.talent?.name.split(" ").join("_") }_CV`)

const uploadInfo = computed(() => {
  return files.value.length === 1 ? files.value[0].name : `${files.value.length} files selected`;
});

const upload_text = computed(() => {
  return files.value.length > 0 ? 'Confirm Upload' : 'Upload new CV';
});

const resumeUrl = () => {
  const url = props.talent.candidate.resume;
  saveResumeUrl(store, url);
};

const previewResume = (e) => {
  if (e === undefined) return;
  files.value = Array.from(e.target.files) || [];
  if (files.value[0].size > 10000000) {
    error.value = true;
  }
  file.value = e.target.files[0];
};

function clearFile() {
  files.value = [];
  error.value = false;
}

const hiring_img = computed(() => {
  if (props.jobDescription.postedBy) return props.jobDescription.postedBy.avatar_url;
  return props.jobDescription.employer.logo_url;
});
const hiring_name = computed(() => {
  if (props.jobDescription.postedBy) return props.jobDescription.postedBy.name;
  return props.jobDescription.employer.name;
});

const hiring_position = computed(() => {
  if (props.jobDescription.postedBy) {
    const position = props.jobDescription.postedBy.position ?
      `${ props.jobDescription.postedBy.position },` : ''
    return `${ position } ${ props.jobDescription.employer.name }`;
  }
  return null;
});

const uploadCv = async () => {
  if (files.value.length == 0) {
    selectedFile.value.click();
    previewResume();
    return
  }
  if (files.value[0]?.type != 'application/pdf') {
    return alert('Invalid file type. Upload a PDF file', 'error');
  }
  try {
    isLoading.value = true;
    disabled.value = true;
    const data = new FormData();
    if (file.value) {
      data.append('resume', file.value);
    }

    const { message } = await store.dispatch('auth/updateUserResume', data);
    alert(message, 'success');
    isLoading.value = false;
    clearFile();
    isLoading.value = false;
    await store.dispatch('auth/getUser', { forceReload: true });
  } catch (error) {
    if (error?.response.status === 422) {
      process.env.NODE_ENV === 'development' ? console.log(error.response.data.errors) : '';
    }
    alert(errorMessage(error), 'error');
    isLoading.value = false;
  } finally {
    files.value = [];
    isLoading.value = false;
    disabled.value = false;
  }
};

onMounted(async () => {
  const form = store.getters['global/getJobForm'];
  if (form.note) {
    note.value = form.note;
  }
  process.env.NODE_ENV === 'development' ? console.log(form) : '';
});

</script>
<style lang="scss">
.text-brand {
  color: #F0386A !important;
}

.mr-5 {
  margin-right: 20px;
}

.h-12 {
  height: 48px;
}
.cv-btn {
  @media screen and (min-width: 768px) {
    position: relative;
    top: 40px;
  }
}
</style>
